import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useContext, useRef, useLayoutEffect } from "react"
import '../styles/pages.scss'
import { texts } from "../texts"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Logo from "../components/Logo/Logo"
import { Context } from "../components/Context"
import logayaut from "logayaut"

import logo_bacc from '../images/logos/bacc.png'
import logo_cc from '../images/logos/cc.png'
import logo_ea from '../images/logos/ea.png'
import logo_er from '../images/logos/er.png'
import logo_pat from '../images/logos/pat.png'
import logo_pqa from '../images/logos/pqa.png'
import logo_ptp from '../images/logos/ptp.png'
import logo_xjc from '../images/logos/xjc.png'
import logo_telegram from '../images/logos/telegram.png'

const IndexPage = () => {
  const { lang } = useContext(Context)
  const $logos_container = useRef()
  const logos_files = [logo_bacc, logo_cc, logo_ea, logo_er, logo_pat, logo_pqa, logo_ptp, logo_xjc]
  
  useLayoutEffect(() => {
    logayaut(logos_files, $logos_container.current, {
      area: window.innerWidth > 800 ? 15000 : 7000,
      densityShrinkFactor: 0.8,
      padding: '15px'
    })
    return () => {
      $logos_container.current.innerHTML = ''
    }
  }, [])

  return (
    <Layout>
      <SEO title='Peaje Urbano' />
      <main id='index'>

        <section id="queremos">
          <Logo showText size='medium' />
          <div className='textvideo'>
            <div className='textvideo__text'>
              <h2>{texts[lang].slide1.heading1}<br></br>{texts[lang].slide1.heading2}</h2>
              <p>{texts[lang].slide1.text}</p>
              <p className='subtext'>{texts[lang].slide1.subtext}</p>
            </div>
            <iframe className="textvideo__video" src={`https://www.youtube.com/embed/${texts[lang].video1}`} title="Campaña BARCELONA’22: De la ciudad de los coches en la ciudad de las personas" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </section>
        
        <section id="proponemos">
          <div className='textvideo'>
            <div className='textvideo__text'>
              <h2>{texts[lang].slide2.heading}</h2>
              <p>{texts[lang].slide2.text1} <b>{texts[lang].slide2.text2}</b> {texts[lang].slide2.text3}</p>
              <div>
                <a className='link' href={`/files/DocTec-${lang}.pdf`} download>{texts[lang].slide2.link1}</a>
                <a className='link' href={`/files/ResTec-${lang}.pdf`} download>{texts[lang].slide2.link2}</a>
              </div>
            </div>
            <iframe className="textvideo__video" src={`https://www.youtube.com/embed/${texts[lang].video2}`} title="Campaña BARCELONA’22: De la ciudad de los coches en la ciudad de las personas" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </section>

        <section id="porque" className='fullwidth'>
          <Logo showText size='big' />
          <div className='sections'>
            <Link to='/sections#section-aire' className='section-link link-aire'>{texts[lang].slide3.aire}</Link>
            <Link to='/sections#section-clima' className='section-link link-clima'>{texts[lang].slide3.clima}</Link>
            <Link to='/sections#section-movilidad' className='section-link link-movilidad'>{texts[lang].slide3.movilidad}</Link>
            <Link to='/sections#section-espacio' className='section-link link-espacio'>{texts[lang].slide3.espacio}</Link>
            <Link to='/sections#section-ruido' className='section-link link-ruido'>{texts[lang].slide3.ruido}</Link>
          </div>
        </section>

        <section id="quienes">
          <h2 className='title'>{texts[lang].slide4.title}</h2>
          <div className='logos' ref={$logos_container}></div>
          <div className='contact'>
            <h2 className='hashtag'>{texts[lang].hashtag}</h2>
            <a className='telegram' href='https://t.me/RecuperemLaCiutat' target='_blank'><img src={logo_telegram} />Telegram</a>
          </div>
        </section>

      </main>
    </Layout>
  )
}

export default IndexPage

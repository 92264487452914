import * as styles from './Header.module.scss'
import React, { useContext, useState } from "react"
import { Context } from '../Context'
import { Link } from 'gatsby'
import Logo from '../Logo/Logo'

const items = [
  {
    text: {
      cast: 'qué queremos',
      cat: 'què volem'
    },
    url: '/#queremos'
  },
  {
    text: {
      cast: 'qué proponemos',
      cat: 'què proposem'
    },
    url: '/#proponemos'
  },
  {
    text: {
      cast: 'por qué',
      cat: 'per què'
    },
    url: '/#porque'
  },
  {
    text: {
      cast: 'quienes somos',
      cat: 'qui som'
    },
    url: '/#quienes'
  },
]

const langs = {
  cast: {
    cast: 'castellano',
    cat: 'castellà'
  },
  cat: {
    cast: 'catalán',
    cat: 'català'
  }
}

export default function Header () {
  const leftItems = items.slice(0, (items.length - 2) / 2)
  const rightItems = items.slice((items.length - 2) / 2)

  const { lang, setLang } = useContext(Context)
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <header>
      <nav className={styles.desktop}>
        <ul className={styles.menu__list}>
          <li className={styles.link} onClick={() => setLang('cast')}><a>{langs.cast[lang]}</a></li>
          <li className={styles.link} onClick={() => setLang('cat')}><a>{langs.cat[lang]}</a></li>
          {leftItems.map((item, i) => (
            <li key={i} className={styles.link}><Link to={item.url}>{item.text[lang]}</Link></li>
          ))}
        </ul>
        <Logo />
        <ul className={styles.menu__list}>
          {rightItems.map((item, i) => (
            <li key={i} className={styles.link}><Link to={item.url}>{item.text[lang]}</Link></li>
          ))}
        </ul>
      </nav>
      <nav className={styles.mobile}>
        <Logo />
        <div className={`${styles.menu__icon} ${openMenu ? styles.open : ''}`} onClick={() => setOpenMenu(o => !o)}></div>
        <div className={`${styles.collapsable} ${openMenu ? styles.open : ''}`}>
          <ul className={styles.menu__list}>
            <li className={styles.link} onClick={() => setLang('cast')}><a>{langs.cast[lang]}</a></li>
            <li className={styles.link} onClick={() => setLang('cat')}><a>{langs.cat[lang]}</a></li>
            {items.map((item, i) => (
              <li key={i} className={styles.link}><Link to={item.url} onClick={() => setOpenMenu(false)}>{item.text[lang]}</Link></li>
            ))}
          </ul>  
        </div>
      </nav>
    </header>
  )
}

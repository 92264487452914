import React, { useContext } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Context } from "../Context"
import * as styles from './Logo.module.scss'
import { texts } from "../../texts"

export default function Logo ({ showText=false, size='small' }) {
  const { lang } = useContext(Context)

  return (
    <div className={`${styles.logo} ${styles[size]}`}>
      {showText && <span className={`${styles.text} ${styles.left}`}>{texts[lang].logo.left}</span>}
      <StaticImage className={styles.image} src="../../images/logobcn22.png" alt="logo" objectFit="contain" />
      {showText && <span className={`${styles.text} ${styles.right}`}>{texts[lang].logo.right}</span>}
    </div>
  )
}
